import { has } from "lodash";
import { AbilityBuilder, PureAbility, AbilityClass } from "@casl/ability";

export const ADD_ACTION = "add";
export const CHANGE_ACTION = "change";
export const DELETE_ACTION = "delete";
export const VIEW_ACTION = "view";
export const COMMENT_ACTION = "comment";

export const CALENDAR_MODULE = "calendar";
export const ARTICLE_MODULE = "article";
export const TASK_MODULE = "task";
export const DOCUMENT_MODULE = "document";
export const TEAM_MODULE = "team";
export const USER_MODULE = "user";
export const SEARCH_MODULE = "search";
export const ALL_MODULE = "all";
export const POLICY_MODULE = "policy";
export const ASSISTANT_MODULE = "assistant";
export const RISK_MODULE = "risk";
export const RISK_MODULE_V2 = "risk-v2";
export const COMMUNITY_MODULE = "community";
export const DASHBOARD_MODULE = "dashboard";
export const AGREEMENT_MODULE = "agreement";
export const NOTE_MODULE = "note";
export const MITIGATION_MODULE = "mitigation";
export const VENDOR_MODULE = "vendor";
export const REPORT_MODULE = "report";
export const QUESTIONNAIRE_MODULE = "questionnaire";
export const DASHBOARDS_REPORTS_MODULE = "dashboards-reports";
export const VENDOR_ASSESSMENT = "assessment";
export const VENDOR_AGREEMENT = "agreement";
export const HORIZON_SCANNING_MODULE = "horizon-scanning";
export const ROI_MODULE = "roi";

export type Actions =
  | typeof ADD_ACTION
  | typeof CHANGE_ACTION
  | typeof DELETE_ACTION
  | typeof VIEW_ACTION
  | typeof COMMENT_ACTION;

export type Modules =
  | typeof CALENDAR_MODULE
  | typeof ARTICLE_MODULE
  | typeof TASK_MODULE
  | typeof DOCUMENT_MODULE
  | typeof TEAM_MODULE
  | typeof USER_MODULE
  | typeof SEARCH_MODULE
  | typeof ALL_MODULE
  | typeof POLICY_MODULE
  | typeof ASSISTANT_MODULE
  | typeof AGREEMENT_MODULE
  | typeof NOTE_MODULE
  | typeof RISK_MODULE
  | typeof COMMUNITY_MODULE
  | typeof DASHBOARD_MODULE
  | typeof VENDOR_MODULE
  | typeof REPORT_MODULE
  | typeof QUESTIONNAIRE_MODULE
  | typeof DASHBOARDS_REPORTS_MODULE
  | typeof HORIZON_SCANNING_MODULE
  | typeof RISK_MODULE_V2
  | typeof ROI_MODULE;

export type Permissions = {
  dashboard: Actions[];
  community: Actions[];
  article: Actions[];
  task: Actions[];
  document: Actions[];
  team: Actions[];
  user: Actions[];
  search: Actions[];
  policy: Actions[];
  assistant: Actions[];
  agreement: Actions[];
  note: Actions[];
  risk: Actions[];
  calendar: Actions[];
  vendor: Actions[];
  report: Actions[];
  questionnaire: Actions[];
  "dashboards-reports": Actions[];
  roi: Actions[];
};

export type PermissionKey = keyof Permissions;
export type PermissionCheckRule = [PermissionKey, Modules];

export type AppAbility = PureAbility<[Actions, Modules]>;
export const AppAbility = PureAbility as AbilityClass<AppAbility>;

const permissionCheckRules: PermissionCheckRule[] = [
  ["dashboard", DASHBOARD_MODULE],
  ["community", COMMUNITY_MODULE],
  ["article", ARTICLE_MODULE],
  ["task", TASK_MODULE],
  ["document", DOCUMENT_MODULE],
  ["team", TEAM_MODULE],
  ["user", USER_MODULE],
  ["search", SEARCH_MODULE],
  ["policy", POLICY_MODULE],
  ["assistant", ASSISTANT_MODULE],
  ["agreement", AGREEMENT_MODULE],
  ["note", NOTE_MODULE],
  ["risk", RISK_MODULE],
  ["calendar", CALENDAR_MODULE],
  ["vendor", VENDOR_MODULE],
  ["report", REPORT_MODULE],
  ["questionnaire", QUESTIONNAIRE_MODULE],
  ["dashboards-reports", DASHBOARDS_REPORTS_MODULE],
  ["roi", ROI_MODULE],
];

export default function defineRulesFor(permissions: Permissions, isAdmin: boolean) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  if (isAdmin) {
    can([ADD_ACTION, CHANGE_ACTION, COMMENT_ACTION, DELETE_ACTION, VIEW_ACTION], ALL_MODULE);
    return rules;
  }

  for (const checkRule of permissionCheckRules) {
    const [permissionKey, module] = checkRule;
    if (has(permissions, permissionKey)) {
      can(permissions[permissionKey], module);
    }
  }

  return rules;
}

export function buildAbilityFor(permissions: Permissions, isAdmin = false): AppAbility {
  return new AppAbility(defineRulesFor(permissions, isAdmin));
}
