import truncate from "lodash/truncate";
import React, { useContext, useMemo, useState } from "react";
import Header from "shared/components/main-content/Header";
import { useCompany } from "shared/context/CompanyProvider";
import ActionBar from "./components/action-bar";
import style from "assets/css/articlesV2.module.css";
import { FeedArticle, ToDo, UpdateArticleParams, UpdateArticleResponse } from "../types";
import ArticleFormMetadata from "./components/article-form-metadata";
import ArticleDetailsMetadata from "./components/article-details-metadata";
import ArticleSummary from "./components/article-summary";
import ArticleClassifications from "./components/article-classifications";
import ArticleHeader from "./components/article-header";
import ArticleToDosModal from "./components/article-to-dos-modal";
import { AxiosResponse } from "axios";
import Spinner from "shared/components/spinner/Spinner";

interface IArticleDetailsContext {
  article: FeedArticle;
  feed?: {
    name: string;
    url: string;
    template_todos: ToDo[];
  };
  hideForm?: boolean;
  hideToDos?: boolean;
  hideNonShareActions?: boolean;
  showToDosModal?: boolean;
  hideNavigation?: boolean;
  disableNextArticle?: boolean;
  disablePreviousArticle?: boolean;
  setShowToDosModal?: (showToDosModal: boolean) => void;
  onPreviousArticle?: () => void;
  onNextArticle?: () => void;
  onMarkAsRead?: () => void;
  onArchive?: () => void;
  onBookmark?: () => void;
  onToDosSubmit?: (toDos: ToDo[]) => void;
  updateArticle?: (
    params: Omit<UpdateArticleParams, "id" | "companyId" | "feedId">
  ) => Promise<AxiosResponse<UpdateArticleResponse>>;
}

const ArticleDetailsContext = React.createContext<IArticleDetailsContext>({
  article: {} as FeedArticle,
  feed: {
    name: "",
    url: "",
    template_todos: [],
  },
});

interface IArticleDetailsProps {
  article: FeedArticle;
  feed?: {
    id: number;
    name: string;
    url: string;
    template_todos: ToDo[];
  };
  headless?: boolean;
  hideForm?: boolean;
  hideToDos?: boolean;
  hideNonShareActions?: boolean;
  hideNavigation?: boolean;
  disableNextArticle?: boolean;
  disablePreviousArticle?: boolean;
  isLoading?: boolean;
  updateArticle?: (
    params: Omit<UpdateArticleParams, "id" | "companyId" | "feedId">
  ) => Promise<AxiosResponse<UpdateArticleResponse>>;
  onPreviousArticle?: () => void;
  onNextArticle?: () => void;
}

const ArticleDetails = ({
  hideToDos,
  updateArticle,
  headless,
  isLoading,
  ...context
}: IArticleDetailsProps) => {
  const { currentCompany } = useCompany();
  const [showToDosModal, setShowToDosModal] = useState(false);

  const { article, feed, hideForm } = context;

  const onMarkAsRead = () => {
    if (!article.is_active) return;
    updateArticle?.({
      is_read: !article.is_read,
    });
  };

  const onArchive = () => {
    if (!article.is_active) return;
    updateArticle?.({
      is_archived: !article.is_archived,
    });
  };

  const onBookmark = () => {
    if (!article.is_active) return;

    updateArticle?.({
      is_saved: !article.is_saved,
    });
  };

  const onToDosSubmit = (toDos: ToDo[]) => {
    const updatedTodos = toDos?.map(({ parent_todo, ...todo }) => {
      return {
        ...todo,
        id: undefined,
        ...(parent_todo ? { parent_todo: parent_todo } : {}),
      };
    });

    updateArticle?.({
      todos: updatedTodos,
    });
  };

  const breadCrumb = useMemo(
    () => [
      {
        id: null,
        name: "Horizon Scanning",
        link: `/workspace/${currentCompany?.id}/horizon-scanning/feeds`,
      },
      {
        id: feed?.id ?? null,
        name: feed?.name ?? "",
        link: `/workspace/${currentCompany?.id}/horizon-scanning/feeds/${feed?.id}`,
      },
      {
        id: Number(article.object_id),
        name: truncate(article.title, { length: 67 }),
        link: `/workspace/${currentCompany?.id}/horizon-scanning/feeds/${feed?.id}/article/${article.object_id}`,
      },
    ],
    []
  );

  return (
    <div className="relative flex flex-col flex-1 w-full bg-white dark:bg-mirage rounded-lg border border-gray-200 dark:border-jet">
      {!headless ? <Header breadCrumb={breadCrumb} /> : null}
      <ArticleDetailsContext.Provider
        value={{
          ...context,
          showToDosModal,
          setShowToDosModal,
          hideToDos,
          onMarkAsRead,
          onArchive,
          onToDosSubmit,
          onBookmark,
          updateArticle,
        }}
      >
        <div className={style.article_details_grid}>
          <ActionBar />
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <ArticleHeader />

              <div className="grid gap-2">
                {!hideForm ? <ArticleFormMetadata /> : null}
                <ArticleDetailsMetadata />
              </div>

              <div className="h-px bg-antiflashwhite" />

              <ArticleSummary />

              <div className="h-px bg-antiflashwhite" />

              <ArticleClassifications />
            </>
          )}
        </div>

        {!hideToDos ? <ArticleToDosModal /> : null}
      </ArticleDetailsContext.Provider>
    </div>
  );
};

export const useArticleDetails = () => useContext(ArticleDetailsContext);

export default ArticleDetails;
