import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Checkbox from "shared/componentsV2/form-controls/checkbox";
import { ToDo as TToDo } from "modules/horizonScanning/types";
import styles from "assets/css/articlesV2.module.css";
import TodoRowActions from "modules/horizonScanning/feeds/components/create-from-scratch-modal/components/to-do/to-do-row-actions";

interface IToDoProps {
  toDo: TToDo;
  onEdit: (newToDo: TToDo) => void;
  disabled?: boolean;
  onDelete?: (toDoId?: string | number) => void;
}

const ToDo = ({ toDo, onDelete, disabled, onEdit }: IToDoProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEdit = (value: string) => {
    if (value.trim() === "") {
      setIsEditing(false);
      return;
    }

    onEdit({
      ...toDo,
      description: value,
    });
    setIsEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleEdit(e.currentTarget.value);
      e.currentTarget.value = "";
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleEdit(e.currentTarget.value);
    e.currentTarget.value = "";
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
    }
  }, [isEditing]);

  return (
    <li
      className={clsx(
        styles.article_to_do,
        "grid items-center grid-cols-[auto_1fr_auto] gap-2 rounded-lg px-3 py-2 bg-ghostwhite",
        {
          "line-through text-aurometalsaurus": toDo.is_completed && !isEditing,
        }
      )}
    >
      <Checkbox
        checked={toDo.is_completed}
        wrapperClassName="!p-0"
        className="w-3.5 h-3.5"
        onChange={(ev) =>
          onEdit({
            ...toDo,
            is_completed: ev.target.checked,
          })
        }
      />

      {isEditing ? (
        <input
          ref={inputRef}
          defaultValue={toDo.description}
          className="w-full bg-transparent text-sm font-medium grow"
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
      ) : (
        <span tabIndex={0}>{toDo.description}</span>
      )}

      {!disabled ? (
        <TodoRowActions
          onEdit={() => {
            setIsEditing(true);
          }}
          onDelete={() => onDelete?.(toDo.id)}
        />
      ) : null}
    </li>
  );
};

export default ToDo;
