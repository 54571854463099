import { Link } from "react-router-dom";
import truncate from "lodash/truncate";

import Icon from "shared/components/icon/Icon";

import ArticleMetadata from "../article-metadata";
import { useArticleDetails } from "../../ArticleDetails";

const ArticleDetailsMetadata = () => {
  const { article } = useArticleDetails();

  const detailsMetadata = [
    {
      label: "Article ID",
      meta: `${article.id}`,
    },
    {
      label: "Published at",
      meta: article.publication_date,
    },
    {
      label: "Source",
      meta: (
        <Link to={article.publisher?.link} target="_blank" className="flex items-center gap-1">
          {truncate(article.publisher?.name, { length: 61 })}
          <Icon type="external-link" fill size="icon-xs" badgeColor="#1C64F2" />
        </Link>
      ),
    },
    {
      label: "Link to original article",
      meta: (
        <Link to={article.link} target="_blank" className="flex items-center gap-1">
          {truncate(article.title, { length: 61 })}
          <Icon type="external-link" fill size="icon-xs" badgeColor="#1C64F2" />
        </Link>
      ),
    },
    {
      label: "Source sections",
      meta: article.sections?.join(" | ") ?? "",
    },
    {
      label: "Topic",
      meta: article.topics?.map((data) => data?.name).join(" | ") ?? "",
    },
  ];

  return <ArticleMetadata collapsible metadatas={detailsMetadata} sectionTitle="Details" />;
};

export default ArticleDetailsMetadata;
