import Modal from "shared/componentsV2/common-modal";
import { useArticleDetails } from "../../ArticleDetails";
import Icon from "shared/components/icon/Icon";
import { FormikProvider, useFormik } from "formik";
import EditToDo from "./components/edit-to-do";
import Button from "shared/componentsV2/form-controls/button";
import { ToDo } from "modules/horizonScanning/types";

interface IArticleToDosModalProps {
  onClose?: () => void;
}

const ArticleToDosModal = ({ onClose }: IArticleToDosModalProps) => {
  const { article, feed, showToDosModal, setShowToDosModal, onToDosSubmit } = useArticleDetails();

  const templateToDos: ToDo[] | undefined = feed?.template_todos.flatMap(({ description, id }) =>
    !article.todos?.some(({ parent_todo }) => parent_todo === id)
      ? {
          id,
          description,
          parent_todo: id !== undefined ? Number(id) : null,
          is_completed: false,
          is_deleted: false,
        }
      : []
  );

  const { values, resetForm, submitForm, ...form } = useFormik({
    initialValues: {
      toDos: [...(templateToDos ?? []), ...(article.todos ?? [])],
    },
    enableReinitialize: true,
    onSubmit: ({ toDos }) => {
      onToDosSubmit?.(toDos);
      setShowToDosModal?.(false);
    },
  });

  const completedTodosAmount = values.toDos.filter(
    ({ is_completed, is_deleted }) => is_completed && !is_deleted
  ).length;

  return (
    <Modal
      onClose={() => {
        setShowToDosModal?.(false);
        resetForm();
        onClose?.();
      }}
      show={showToDosModal}
      size="4xl"
    >
      <FormikProvider value={{ values, resetForm, submitForm, ...form }}>
        <Modal.Header>
          <div className="flex items-center gap-2 text-sm font-medium text-richblack">
            <Icon type="in-progress" />
            To-do
            {values.toDos.length ? (
              <span>
                {completedTodosAmount}/{values.toDos.filter(({ is_deleted }) => !is_deleted).length}
              </span>
            ) : null}
          </div>
        </Modal.Header>
        <Modal.Body className="!px-8 !py-6">
          <div className="grid gap-4">
            <span className="text-sm font-medium text-aurometalsaurus">
              Create a checklist of default to-dos for every article in this feed. You can add
              specific to-dos directly on the article page.
            </span>
            <ul className="grid gap-2 list-none w-full text-sm font-medium">
              {values.toDos.map((toDo) =>
                !toDo.is_deleted ? <EditToDo key={toDo.id} toDo={toDo} /> : null
              )}
              <input
                className="w-full py-2.5 px-3 bg-gray-50 rounded-lg placeholder:text-gray-400"
                placeholder="Add item..."
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    if (!e.currentTarget.value.trim()) {
                      return;
                    }

                    form.setFieldValue("toDos", [
                      ...values.toDos,
                      {
                        id: Math.random(),
                        description: e.currentTarget.value,
                      },
                    ]);

                    e.currentTarget.value = "";
                  }
                }}
              />
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-end items-center gap-2 !px-8 !py-3 border-0">
          <Button
            btnTitle="Discard"
            btnType="secondary"
            btnSize="sm"
            onClick={() => {
              setShowToDosModal?.(false);
              resetForm();
            }}
          />
          <Button btnTitle="Save" btnSize="sm" onClick={submitForm} />
        </Modal.Footer>
      </FormikProvider>
    </Modal>
  );
};

export default ArticleToDosModal;
