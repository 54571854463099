import { ToDo as TToDo } from "modules/horizonScanning/types";
import { useFormikContext } from "formik";
import ToDo from "../to-do";

interface IToDoProps {
  toDo: TToDo;
}

const EditToDo = ({ toDo }: IToDoProps) => {
  const { values, setFieldValue } = useFormikContext<{ toDos: TToDo[] }>();

  const handleEdit = (newToDo: TToDo) =>
    setFieldValue(
      "toDos",
      values.toDos.map((td) => (td.id === toDo.id ? newToDo : td))
    );

  const handleDelete = () =>
    setFieldValue(
      "toDos",
      values.toDos.map((td) => {
        if (td.id === toDo.id) {
          return { ...td, is_deleted: true };
        }
        return td;
      })
    );

  return <ToDo toDo={toDo} onEdit={handleEdit} onDelete={handleDelete} />;
};

export default EditToDo;
