import Icon from "shared/components/icon/Icon";
import Badge from "shared/componentsV2/form-controls/badge";
import { useArticleDetails } from "../../ArticleDetails";

const ArticleHeader = () => {
  const { article, setShowToDosModal, hideToDos } = useArticleDetails();

  const completedTodosAmount =
    article.todos?.filter(({ is_completed, is_deleted }) => is_completed && !is_deleted).length ??
    0;

  return (
    <div className="grid gap-3">
      {article.title ? (
        <h1 className="text-xl font-semibold text-richblack dark:text-lighthouse">
          {article.title}
        </h1>
      ) : null}
      {article.original_title ? (
        <div className="flex gap-1 text-xs">
          <span className="font-semibold">Original title:</span>
          <span className="font-normal">{article.original_title}</span>
        </div>
      ) : null}
      <div className="flex gap-2">
        {article.is_classified ? (
          <Badge
            badgeColor="#4B5563"
            icon={<Icon type="squares-check" size="icon-xxs" />}
            badgeTitle="Classified"
          />
        ) : null}
        {!hideToDos ? (
          <Badge
            className="!border-brightgray dark:border-thunders cursor-pointer"
            icon={<Icon type="to-do" size="icon-xxs" />}
            badgeColor="#4B5563"
            badgeBgColor="transparent"
            badgeTitle={
              <div className="flex gap-1 cursor-pointer">
                To do
                <span className="text-newcar">{`${completedTodosAmount}/${
                  article.todos?.filter(({ is_deleted }) => !is_deleted)?.length ?? 0
                }`}</span>
              </div>
            }
            badgeType="secondary"
            onClick={() => (!article.is_active ? null : setShowToDosModal?.(true))}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ArticleHeader;
