import { AxiosResponse } from "axios";

import api from "shared/api/v1";
import { PaginatedResponse } from "../../../../shared/typesV2";

export default async function getRegulations(params: {
  company: number;
}): Promise<AxiosResponse<PaginatedResponse<{ name: string; id: string }>>> {
  return api.get<PaginatedResponse<{ name: string; id: string }>>(`/horizon-scanning/regulations`, {
    params,
  });
}
