import { ArticleClassifications } from "modules/horizonScanning/types";
import ArticleSection from "../article-section";
import { useArticleDetails } from "../../ArticleDetails";

const ArticleSummary = () => {
  const { article } = useArticleDetails();

  const classifications = [
    {
      title: ArticleClassifications.relevantRegulations,
      classification: article.regulations?.map((data) => data?.name).join(""),
    },
    {
      title: ArticleClassifications.mitigatingEfforts,
      classification: article.mitigating_efforts?.map((data) => data?.name).join(""),
    },
    {
      title: ArticleClassifications.potentialImpact,
      classification: article.potential_impact?.map((data) => data?.name).join(""),
    },
    {
      title: ArticleClassifications.timeline,
      classification: article.timeline?.map((data) => data?.name).join(""),
    },

    {
      title: ArticleClassifications.institutionTypes,
      classification: article.institution_types?.map((data) => data?.name).join(""),
    },
    {
      title: ArticleClassifications.regulatoryJurisdiction,
      classification: article.jurisdictions?.map((data) => data?.name).join(""),
    },
  ];

  return (
    <ArticleSection title="Classifications" icon="squares-check">
      <div className="grid gap-2">
        {classifications.map(({ classification, title }) =>
          classification ? (
            <div className="grid gap-2 border border-antiflashwhite rounded-lg p-4 bg-ghostwhite font-medium">
              <span className="text-xs text-aurometalsaurus">{title}</span>
              <span className="text-sm text-richblack">{classification}</span>
            </div>
          ) : null
        )}
      </div>
    </ArticleSection>
  );
};

export default ArticleSummary;
